<div
    id="questionnaire-result"
    v-if="calculateResult && calculateResult.products.length"
    class="questionnaire-result position-relative"
>
    <slot name="loader"></slot>
    <input type="checkbox" id="qr-collapse" class="hide">
    <div class="header">
        <div class="row d-flex no-gap">
            <label for="qr-collapse" class="icon-wrapper">
                <span class="icon chevrons"></span>
            </label>
            <label for="qr-collapse" class="h5 title">Результаты подбора</label>

            <scenario-assessment class="ml-1" :scenario-ratings="scenarioRatings" />

            <div v-if="calculateResult.calculate_mode==='list'" class="title" style="color: darkred">ТОВАРЫ ВЫВОДЯТСЯ БЕЗ ПОДБОРА
                ПОД ВОПРОСЫ
            </div>

            <FormRadio
                v-if="isCompareAvailable"
                type="checkbox"
                v-model="displayCompareProducts"
                label="Сравнить выбранные"
                wrapper-class="inline  ml-auto text-orange"
                :attributes="{type:'checkbox',class:'check-showall'}"
            />
            <FormRadio
                label="Комплектация"
                v-model="displayEquipmentField"
                wrapper-class="inline ml-auto"
                :attributes="{type:'checkbox',class:'check-showall'}"
            />
            <FormRadio
                v-if="false"
                label="Показать все"
                wrapper-class="inline ml-auto"
                :attributes="{type:'checkbox',class:'check-showall'}"
            />
            <FormRadio
                type="checkbox"
                @change="inStockChange($event)"
                label="В наличии"
                wrapper-class="inline"
                :attributes="{type:'checkbox',class:'check-instock'}"
            />
            <FormRadio
                type="checkbox"
                @change="onDisplayCalculationDetails($event)"
                label="Детали"
                wrapper-class="inline text-gray"
                :attributes="{type:'checkbox',class:'check-instock'}"
            />
        </div>
    </div>
    <div class="content">
        <template v-for="item in calculateResult.products">
            <div class="item mt-2 mb-2" v-if="item.published">
                <div>
                    <div class="balance balance-top row d-flex justify-content-end">
                        <span class="count branch">{{ item.count }}</span> /
                        <tippy
                            :key="'countDetails-'+ item.external_id"
                            :content="generateCountBlock(item.countDetails, item.fastestDelivery)"
                            allowHTML
                            arrow
                            arrowType="round"
                            theme="light"
                            placement="bottom"
                        >
                            <span class="count all availability-button" @click="() => {toggleAvailabilityBlock(item.id)}">{{ item.full_count }} шт.</span>
                        </tippy>
                        <product-availability
                            :view-availability="viewAvailability === item.id"
                            :popup-title="'Доступность ' + item.name"
                            :titles="calculateResult?.fullAvailability?.titles"
                            :availability-details="calculateResult?.fullAvailability?.availability[item.id]"
                            :branches="this.branches"
                            :current-branch-id="this.questionnaireOptions.filial_id"
                            :on-toggle-view-availability="toggleAvailabilityBlock"
                        />
                    </div>
                    <div class="row d-flex">
                        <!-- Чекбокс для мультиоферт, пока выключен-->
                        <div class="inline form-check check-product form-ii" v-if="false">
                            <input :id="item.id"
                                type="checkbox"
                                class="check-calculate hide"
                                name="form-check-form-checkbox-product[]">
                            <label :for="item.id"><i class="icon check"></i></label>
                        </div>

                        <img v-if="item.preview_url" :src="item.preview_url" alt="">
                        <div class="info">
                            <a
                                v-if="item.link"
                                class="title"
                                :href="item.link"
                                target="_blank"
                                :title="item.name"
                            >
                                {{ item.name }}
                            </a>
                            <span v-else class="title disabled" :title="item.name">{{ item.name }}</span>
                        </div>
                        <div v-if="displayEquipmentField && item.options.length">
                            <div v-for="option in item.options" class="description">
                                &#x2022; {{ option.metadata.title }}
                            </div>
                        </div>
                    </div>

                    <!-- Цена ЗЧ -->
                    <PartPrice
                        v-if="questionnaire.isPart"
                        class="mt-1"
                        :part="item"
                        :filial-id="questionnaireOptions.filial_id"
                    />

                    <div class="container-balance open">
                        <div
                            v-if="
                                !displayProductPrices
                                && variables.finance
                                && item?.prices?.price?.local_price > 0
                                && typeof item?.prices?.price?.local_price !== 'undefined'"
                            class="button-price"
                            @click="displayFinPopup(item)"
                        >
                            Цены
                        </div>
                        <template v-else-if="item.prices" v-for="(price, id, index) in item.prices">
                            <div
                                class="row d-flex align-items-center row-balance"
                                v-if="
                                    price.local_price > 0
                                    && priceTool.priceTypeDetails[id].display
                                    // && (index < 3 || displayedAllProductPrices.includes(item.external_id))
                                "
                            >
                                <div class="d-flex align-items-center">
                                    <FormCheckbox :title="'добавить в КП'"
                                        :attributes="{
                                            name:'compare_ids',
                                            value:JSON.stringify({
                                                product_code: item.external_id,
                                                price: price
                                            }),
                                            checked: isCheckedPrice(item.external_id, id),
                                        }"
                                        @input="productChangeHandler($event, item, id)"
                                    />

                                    <div class="balance">
                                        <span
                                            :class="'price' /*+ priceTool?.priceTypeDetails[id]?.priceClass.join(' ')*/"
                                        >
                                            {{ formattedPrice(price.local_price, price.local_currency) }}
                                        </span>
                                    </div>
                                </div>
                                <div class="marker-container" v-if="priceTool.priceTypeDetails[id].title">
                                    <div :class="'marker ' + ((selectedFinancialProduct === id) ? 'red' : 'gray')">
                                        <template v-if="price.details">
                                            <tippy
                                                :key="'priceDetails-'+ id + item.external_id"
                                                :content="generateNote(price.details)"
                                                allowHTML
                                                arrow
                                                theme="big"
                                            >
                                                <span class="title">{{ priceTool.priceTypeDetails[id].title }}</span>
                                            </tippy>
                                        </template>
                                        <template v-else>
                                            <span class="title">{{ priceTool.priceTypeDetails[id].title }}</span>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>

<!--                    <div class="more-price" @click="openPrice(item.external_id)"-->
<!--                        v-if="-->
<!--                            !displayedAllProductPrices.includes(item.external_id)-->
<!--                            && getDisplayedPrices(item.prices).length > 2-->
<!--                            && (displayProductPrices || !variables.finance)-->
<!--                        "-->
<!--                    >-->
<!--                        Еще цены-->
<!--                    </div>-->

                    <div class="percent tooltip-source" :data-tooltip-source="item.result_popup">
                        <template v-for="(value, key) in item.graph">
                            <span
                                v-if="value"
                                :class="key"
                                :style="`max-width: ${value}%; flex: 0 0 ${value}%;`"
                            >{{ value }}%</span>
                        </template>
                    </div>

                    <div class="p-2" v-if="displayCalculationDetails && item.points?.points_details">
                        <template v-for="detail in item.points.points_details">
                            <ul v-if="detail.points !== 0">
                                <strong>{{detail.question}}</strong> -> {{detail.points}}
                                <template v-for="answer in detail.answers">
                                    <li v-if="answer.points !== 0">
                                        {{answer.answer}} -> {{answer.points}}
                                        <template v-for="property in answer.product_props">
                                            <div class="ml-2">
                                                | {{property.property_name}}="{{property.product_value}}"
                                                --> {{property.points}}
                                            </div>
                                        </template>
                                    </li>
                                </template>
                            </ul>
                        </template>
                    </div>
                </div>
            </div>
        </template>
    </div>
    <FinanceQuestion
        v-if="variables && variables.finance"
        :variables="variables.finance"
        :questions="questions"
        :priceList="getPricesFromProductForFinPopup"
        v-model:display-financial-popup="displayFinancialPopup"
        :on-select-finance-product="selectFinancialProduct"
    />
    <compare-products
        v-if="displayCompareProducts && calculateTitles && detailsForCompare"
        :modes="compareModes"
        :titles="calculateTitles"
        :products="productsForCompare"
        :questions="questionsSource"
        :details="detailsForCompare"
        v-model:display-compare-products-popup="displayCompareProducts"
    >
        <template v-slot:header>
            <scenario-assessment class="ml-1" :scenario-ratings="scenarioRatings" />
        </template>
    </compare-products>
</div>
